<template>
  <div>
    <div class="mt-5"></div>
  </div>
</template>

<script>
import axios from "@axios";

export default {
  components: {},
  data() {
    return {};
  },
  created() {
    axios
      .post("/auth/confirmEmail", {
        token: this.$route.params.token,
      })
      .then((res) => {
        this.$swal({
          title: "Eposta Onaylandı",
          text: "Eposta adresinizi onayladığınız için teşekkürler",
          icon: "success",
          confirmButtonText: "Giriş Yap",
        }).then(() => {
          this.$router.push("/");
        });
      });
  },
};
</script>

<style>
body {
  transition: all 0.5s ease;
}
</style>
